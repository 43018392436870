import React from 'react'
import Select from 'react-select';


const EnergyRating = (props) => {
  return (
                                    <Select
                                       placeholder={
                                        props.energy_rating
                                          ? props.energy_rating
                                          : "Min. Energy Rating"
                                         }
                                         isSearchable={false}
                                          onChange={e =>
                                            props.setEnergyRating(e.value)
                                          }
                                          value={props.energyRating?.find(
                                            obj => obj.value === props.energy_rating
                                          )}
                                           options={props.energyRating}
                                           classNamePrefix="select-energy-rating"
                                            /> 
  )
}

export default EnergyRating