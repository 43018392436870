import React, { useState, useEffect } from "react"
import CustomSelect from "../../CustomSelect/CustomSelect"
import Select from "react-dropdown-select"
import $ from "jquery"
// import Select from 'react-select';
import _, {
  isEmpty,
  filter,
  find,
  isArray,
  isString,
  get,
  indexOf,
  cloneDeep
} from "lodash"
import { navigate } from "@reach/router"
import axios from "axios"
import {
  resiSalesPropertyType,
  resiLettingsPropertyType,
  priceminRangeResiSale,
  pricemaxRangeResiSale,
  priceminRangeResiRent,
  pricemaxRangeResiRent,
  bedroomsRange,
  commercialSalesPropertyType,
  energyRating,
  PropertySize,
  PropertySizeMax,
  Styles
} from "../../common/propertyUtils"
import SearchResultInputBox from "./SearchResultBox"
import SearchMultipleArea from "./search-multiarea"
import SearchKeyword from "./SearchKeyword"
import AreaListBox from "./AreaList"
import "./SecondaryHeader.scss"
import { getAreas } from "../../property-search/utils"
import { Container } from "react-bootstrap"
import EnergyRating from "./EnergyRating";
import { isMobile } from "react-device-detect"
import { useWindowSize } from "../../../hooks/window-size"
// import EnergyRating from "./EnergyRating"

const SecondaryHeader = props => {

  const [windowWidth] = useWindowSize()
  //
  const [areaVal, setAreaVal] = useState("")
  const [areaName, setAreaName] = useState("")
  const [areaUrl, setAreaUrl] = useState("")
  const [minpriceVal, setMinPriceVal] = useState("")
  const [maxpriceVal, setMaxPriceVal] = useState("")
  const [propertyval, setPropertyVal] = useState("")
  const [salesVal, setSalesVal] = useState("")
  const [bedVal, setBedVal] = useState("")
  const [minPriceList, setMinPriceList] = useState([])
  const [maxPriceList, setMaxPriceList] = useState([])
  const [h1_areas_temp, setH1Areas_Temp] = useState([])
  const [energy_rating, setEnergyRating] = useState("")
  const [styleVal, setStyleVal] = useState("")
  const [agVal, setagVal] = useState("")
  const [auction_date, setAuctionDate] = useState(
    props.auction_date ? props.auction_date : ""
  )

  const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0)
  const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0)

  const [minsizeVal, setMinSizeeVal] = useState(0)
  const [maxsizeVal, setMaxSizeVal] = useState(0)

  const [statusVal, setStatusVal] = useState(props.statusType)

  // Multi area search
  const [areasList, setAreasList] = useState([])
  const [areasArr, setAreasArr] = useState([])

  const [mustInclude_Arr, setMustIncludeArr] = useState([])
  const [focus_cls, setFocusValue] = useState(false)

  var min_property_size = []
  var max_property_size = []

  if (maxsizeVal > 0) {
    min_property_size = PropertySize.filter(
      item => parseInt(item.value) < maxsizeVal
    )
  } else {
    min_property_size = PropertySize
  }

  if (minsizeVal > 0) {
    max_property_size = PropertySizeMax.filter(
      item => parseInt(item.value) > minsizeVal
    )
  } else {
    max_property_size = PropertySizeMax
  }

  var min_size_options = [
    { label: "No Min. Size", value: 0 },
    ...min_property_size,
  ]
  var max_size_option = [
    { label: "No Max. Size", value: 0 },
    ...max_property_size,
  ]


      
// {label: 'All', value: ''}
// {label: 'Detached', value: 'detached'}
// {label: 'End Terrace', value: 'end-terrace'}
// {label: 'Mews', value: 'mews'}
// {label: 'Penthouse', value: 'penthouse'}
// {label: 'Semi Detached', value: 'semi-detached'}
// {label: 'Studio', value: 'studio'}


  //handling property types
  let StyleTypes=Styles;
      if(StyleTypes){
        if(propertyval==="apartment"){
          StyleTypes= StyleTypes.filter(type=>{
            return type.label!=="Detached"&&type.label!=="End Terrace"&&type.label!=="Semi Detached"
          })
        }
        if(propertyval==="house"){
          StyleTypes= StyleTypes.filter(type=>{
            return type.label!=="Mews"&&type.label!=="Penthouse"&&type.label!=="Studio"
          })
        }
        if(propertyval==="site"||propertyval==="investment"||propertyval==="farm"||propertyval==="terraced-house"||propertyval==="bungalow"){
          StyleTypes= StyleTypes.filter(type=>{
            return type.label!=="Mews"&&type.label!=="Penthouse"&&type.label!=="Studio"&&type.label!=="Detached"&&type.label!=="End Terrace"&&type.label!=="Semi Detached"
          })
        }

      }
  /////-----------------////

  const handleMultiAreaFun = val => {
    let areas = []
    if (_.isEmpty(find(areasArr, val))) {
      areas = [...areasArr]
      areas.push(val)
      setAreasArr(areas)
      setAreaVal("")
    }
  }

  const handlCheck = e => {
    if (e.target.checked === true) {
      if(e.target.value === "sale agreed" || e.target.value === "let agreed") {
        setagVal("checked")
      }
      setStatusVal([...statusVal, e.target.value])
    }
    if (e.target.checked === false) {
      if(e.target.value === "sale agreed" || e.target.value === "let agreed") {
        setagVal("unchecked")
      }
      var remove_val =
        statusVal.length > 0 &&
        statusVal.filter(item => item.toLowerCase() !== e.target.value);
      setStatusVal(remove_val)
    }
  }

  const closeSearchFilter = e => {
    $(".search-filter-dropdown").removeClass("active-filter")
    setFocusValue(false)
    if(windowWidth<768){
      const getElement=document.getElementById("residentail-search-lists");
      getElement.style.opacity=1
    }

  }

  //console.log("pathUri", statusVal);

  const removeArea = (area, area_disp_name, clear = false) => {
    if (!clear) {
      let prevAreas = areasArr.filter(x => x.slug !== area)
      let prevAreasName = h1_areas_temp.filter(x => x !== area_disp_name)
      setAreasArr(prevAreas)
      setH1Areas_Temp(prevAreasName)
    } else {
      setAreasArr([])
      setH1Areas_Temp([])
    }
  }
  // Multi area search

  //console.log("h1_areas_temp", h1_areas_temp);

  // Min price
  const onChangeMinPrice = e => {
    setSelectedMinPriceValue(e[0].value)
  }
  // Min price

  // Max Price
  const onChangeMaxPrice = e => {
    setSelectedMaxPriceValue(e[0].value)
  }
  // Max Price

  // Sales price list
  var minPrice = []
  var maxPrice = pricemaxRangeResiSale.filter(
    c => c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)
  )

  priceminRangeResiSale.map(
    items =>
      parseInt(items.value) < parseInt(selectedMaxPriceValue) &&
      minPrice.push(items)
  )

  if (minPrice.length > 0) {
  } else {
    minPrice.push(priceminRangeResiSale[0])
  }

  if (maxPrice.length > 0) {
  } else {
    maxPrice.push(pricemaxRangeResiSale[0])
  }
  // Sales price list

  //console.log("minPrice ==>", minPrice, maxPrice)

  // Lettings price list
  var minPriceLettings = []
  var maxPriceLettings = pricemaxRangeResiRent.filter(
    c => c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)
  )

  priceminRangeResiRent.map(
    items =>
      parseInt(items.value) < parseInt(selectedMaxPriceValue) &&
      minPriceLettings.push(items)
  )

  if (minPriceLettings.length > 0) {
  } else {
    minPriceLettings.push(priceminRangeResiRent[0])
  }

  if (maxPriceLettings.length > 0) {
  } else {
    maxPriceLettings.push(pricemaxRangeResiRent[0])
  }
  // Lettings price list

  useEffect(async () => {
    $(".bedrooms_select, .property_type_select").on("click", function () {
      $(".price_dropdown, .size_dropdown").removeClass("active-class")
    })

    //
    if (_.isEmpty(minPriceList)) {
      if (props.propertyType === "lettings") {
        setMinPriceList(priceminRangeResiRent)
        setMaxPriceList(pricemaxRangeResiRent)
      } else {
        setMinPriceList(priceminRangeResiSale)
        setMaxPriceList(pricemaxRangeResiSale)
      }
    }
    //

    //
    $(document).mouseup(function (e) {
      var container = $(".search-filter-dropdown")
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".search-filter-dropdown").removeClass("active-filter")
      }

      var container1 = $(".price_dropdown")
      // if the target of the click isn't the container nor a descendant of the container
      if (!container1.is(e.target) && container1.has(e.target).length === 0) {
        $(".price_dropdown").removeClass("active-class")
      }

      var container2 = $(".size_dropdown")
      // if the target of the click isn't the container nor a descendant of the container
      if (!container2.is(e.target) && container2.has(e.target).length === 0) {
        $(".size_dropdown").removeClass("active-class")
      }
    })
    //

    // Page when load field in text boxes will same
    const url = typeof window !== "undefined" ? window.location.href : ""
    let pathUri = url.split("/")

    for (let vi = 1; vi <= pathUri.length; vi++) {
      // check for area
      if (typeof pathUri[vi] === "undefined") {
        continue
      }

      // Property type
      if (pathUri[vi].indexOf("type-") >= 0) {
        setPropertyVal(pathUri[vi].replace("type-", ""))
      }
      // Property type

      // Area
      if (
        pathUri[vi].indexOf("in-") == 0 &&
        pathUri[vi].indexOf("in-ireland") == -1
      ) {
        // areaVal = pathUri[vi].replace("in-", "")
        var areaArrfilt = pathUri[vi].replace("in-", "")
        // setAreaVal(areaArrfilt.split("-and-"))
        //props?.setAreaList(areaArrfilt.split("-and-"))
        var areaSplit = areaArrfilt.split("-and-")
        var get_areas = await getAreas()
        let filterArea = get_areas
          .filter(c => areaSplit.includes(c.slug))
          .map(item => item.slug)
        let filterAreaArr = get_areas.filter(c => areaSplit.includes(c.slug))

        //props?.setAreaList(filterArea)
        if (filterArea.length > 0) {
          props?.setOnLoadAreaList(filterArea)
          props?.setAreaList(filterArea)
          setAreasArr(filterAreaArr)
        } else {
          //console.log("areaSplit ===>", areaSplit)
          props.freeTextSearch(areaSplit)
        }

        var areaTag = areaArrfilt.split("-and-")
        var selectedAreaTag = []

        props?.newAreaList?.map(arrItem => {
          if (areaTag.includes(arrItem.slug)) {
            // if(arrItem.slug === item) {
            selectedAreaTag.push(arrItem)
          }
        })
      }
      // Area

      // Price
      if (
        pathUri[vi].indexOf("between-") >= 0 ||
        pathUri[vi].indexOf("above-") >= 0 ||
        pathUri[vi].indexOf("under-") >= 0
      ) {
        let priceFilt1 = pathUri[vi].split("above-")
        if (priceFilt1[1]) {
          setMinPriceVal(priceFilt1[1])
        }
        let priceFilt2 = pathUri[vi].split("under-")
        if (priceFilt2[1]) {
          setMaxPriceVal(priceFilt2[1])
        }
        let priceFilt3 = pathUri[vi].split("between-")
        if (priceFilt3[1]) {
          let priceFilt4 = priceFilt3[1].split("-and-")
          setMinPriceVal(priceFilt4[0])
          setMaxPriceVal(priceFilt4[1])
        }
      }
      // Price

      // Size
      if (
        pathUri[vi].indexOf("size-from-") >= 0 ||
        pathUri[vi].indexOf("size-over-") >= 0 ||
        pathUri[vi].indexOf("size-below-") >= 0
      ) {
        let priceFilt1 = pathUri[vi].split("size-over-")
        if (priceFilt1[1]) {
          let selectedVal = PropertySize.find(obj => obj.value == priceFilt1[1])
          console.log('bedroom', selectedVal, priceFilt1[1])
          setMinSizeeVal(selectedVal?.label)

        }
        let priceFilt2 = pathUri[vi].split("size-below-")
        if (priceFilt2[1]) {
          let selectedVal = PropertySizeMax.find(obj => obj.value == priceFilt2[1])
          console.log('bedroom', selectedVal)
          setMaxSizeVal(selectedVal?.label)
        }
        let priceFilt3 = pathUri[vi].split("size-from-")
        if (priceFilt3[1]) {
          let priceFilt4 = priceFilt3[1].split("-to-")
          let selectedValMin = PropertySize.find(obj => obj.value == priceFilt4[0])
          setMinSizeeVal(selectedValMin?.label)
          let selectedValMax = PropertySizeMax.find(obj => obj.value == priceFilt4[1])
          setMaxSizeVal(selectedValMax?.label)
        }
      }
      // Size

      // Bedrooms 
      if (pathUri[vi].indexOf("-and-more-") >= 0) {
        setBedVal(pathUri[vi].replace("-and-more-bedrooms", ""))
      }
      // Bedrooms

      // Style
      if (pathUri[vi].indexOf("with-") >= 0) {
        let priceFilt3 = pathUri[vi].split("with-")
        if (priceFilt3[1]) {
          let priceFilt4 = priceFilt3[1].split("-and-");
          for (let vi = 0; vi <= priceFilt4.length; vi++) {
            let matchStyle = StyleTypes.find(obj => obj.value == priceFilt4[vi])
            if (matchStyle) {
              setStyleVal(matchStyle.value)
            }
          }
        }

      }
      // Style

      //Energy Rating
      if (pathUri[vi].indexOf("-rating") >= 0) {
        var enrgyVal = energyRating.find(
          obj => obj.value === parseInt(pathUri[vi].replace("-rating", ""))
        )

        props.setEnegeryRating(enrgyVal.value)
        setEnergyRating(enrgyVal.label)
      }


      // Auction Date
      if (pathUri[vi].indexOf("date-") >= 0) {
        setAuctionDate(pathUri[vi].replace("date-", ""))
      }
      // Auction Date
    }
  }, [])

  useEffect(() => {
    if (props?.free_text_search) {
      //console.log("free_text_search", props?.free_text_search);
      setAreaVal(props?.free_text_search.toString())
    }
  }, [props?.free_text_search])

  // Multi area
  React.useEffect(() => {
    // if (props.auction_date !== auction_date) {
    //   setBedVal("")
    // }

    if (props.auction_date) {
      setAuctionDate(props.auction_date)
    } else {
      setAuctionDate("")
    }

    if (
      props.searchState?.refinementList?.search_areaslist &&
      areasList.length > 0
    ) {
      let search_areas = Array.isArray(
        props.searchState?.refinementList?.search_areaslist
      )
        ? props.searchState?.refinementList?.search_areaslist
        : [props.searchState?.refinementList?.search_areaslist]

      let filterArea = areasList.filter(c => search_areas.includes(c.slug))
      setAreasArr(filterArea)

      if (filterArea.length === 0) {
        props?.freeTextSearch(search_areas)
      } else {
        //props.freeTextSearch([]);
      }
      if (filterArea?.length > 0) {
        props.setH1Areas(filterArea.map(item => item.display_name))
      }
    }
  }, [
    areasList,
    props.searchState?.refinementList?.search_areaslist,
    props.auction_date,
  ])
  // Multi area

  // useEffect(() => {
  //   props.freeTextSearch(areaVal);
  // },[areaVal])

  // Submit search

  const submitSearch = e => {
    e.preventDefault()

    $(".search-filter-dropdown").removeClass("active-filter")
    $(".price_dropdown, .size_dropdown").removeClass("active-class")
    if(windowWidth<768){
    const getElement=document.getElementById("residentail-search-lists");
    getElement.style.opacity=1
    }


    // var areaValue = $(".location-search input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();

    //console.log("areaVal ==>", areaVal.replace(/ /g, "-").toLowerCase())

    //var areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")

    //console.log("areasArr", areaVal, areasArr);

    //console.log("submitSearch")

    
    $("#sale_agreed_hidden").val(agVal)
    

    var mustIncludeValue = mustInclude_Arr

    if (props?.setEnegeryRating) {
      props.setEnegeryRating(energy_rating)
    }

    var areaValue = ""

    // if(areaVal){
    //   props.freeTextSearch([areaVal]);
    // } else{
    //   props.freeTextSearch([]);
    // }

    if (areaVal) {
      props?.freeTextSearch([areaVal])
      areaValue = areaVal
        .replace(/ /g, "-")
        .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        .toLowerCase()
    } else {
      // if(props.free_text_search.length > 0){
      //   areaValue = props.free_text_search[0].replace(/ /g, "-").toLowerCase();
      // } else{
      //   props.freeTextSearch([]);
      // }
      props?.freeTextSearch([])
    }

    if (areasArr?.length > 0) {
      areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")
      //props.freeTextSearch([]);
      props.setH1Areas(h1_areas_temp)
    } else {
      props.setH1Areas([])
    }

    //console.log("areaValue 2", areaValue, areaVal, props.free_text_search);

    // console.log("$$$$---crm---", areasArr)

    var bedroomValue = bedVal
      ? bedVal
      : $(".bedroom-input input[type='hidden']").val()

    var energyRatingValue = energy_rating ? energy_rating : "";


    var buildingValue = propertyval
      ? propertyval
      : $(".property input[type='hidden']").val()
    let priceUrl = ""
    if (maxpriceVal || minpriceVal) {
      if (
        minpriceVal &&
        maxpriceVal &&
        minpriceVal != "0" &&
        maxpriceVal != "0"
      ) {
        priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
      } else if (
        (minpriceVal == "" || minpriceVal == "0") &&
        maxpriceVal &&
        maxpriceVal != "0"
      ) {
        priceUrl = "under-" + maxpriceVal
      } else if (
        minpriceVal &&
        (maxpriceVal == "" || maxpriceVal == "0") &&
        minpriceVal != "0"
      ) {
        priceUrl = "above-" + minpriceVal
      }
    }

    // Size
    let sizeUrl = ""
    if (maxsizeVal || minsizeVal) {
      if (minsizeVal && maxsizeVal && minsizeVal != "0" && maxsizeVal != "0") {
        sizeUrl = "size-from-" + minsizeVal + "-to-" + maxsizeVal
      } else if (
        (minsizeVal == "" || minsizeVal == "0") &&
        maxsizeVal &&
        maxsizeVal != "0"
      ) {
        sizeUrl = "size-below-" + maxsizeVal
      } else if (
        minsizeVal &&
        (maxsizeVal == "" || maxsizeVal == "0") &&
        minsizeVal != "0"
      ) {
        sizeUrl = "size-over-" + minsizeVal
      }
    }


    //Status val  
    var includes = [];
    if (statusVal?.length > 0) {
      var includesArr = cloneDeep(statusVal).splice(1, statusVal.length);

      if (includesArr.length > 0) {
        includes = includesArr.map(items => items.toLowerCase())
      }
    }

    //Status val

    var searchFilterResults = ""

    //console.log("areaValue ===> $$$", areaValue);

    //console.log("auction_date ==>", auction_date, props.auction_date)

    if (areaValue) {
      if (props.propertyMap === "map") {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property-map/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults = "/property-map/to-rent/in-" + areaValue + "/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults =
              "/commercial-property-map/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults =
              "/commercial-property-map/to-rent/in-" + areaValue + "/"
          }
        }
      } else if (props.tag === "auction") {
        searchFilterResults = "/auction-property/for-sale/in-" + areaValue + "/"
        if (props.Alias === "upcoming-auctions") {
          searchFilterResults =
            "/services/property-auctions/upcoming-auctions/date-" +
            auction_date +
            "/"
        }
      } else {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults = "/property/to-rent/in-" + areaValue + "/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults =
              "/commercial-property/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults =
              "/commercial-property/to-rent/in-" + areaValue + "/"
          }
        }
      }
    } else {
      if (props.propertyMap === "map") {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property-map/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/property-map/to-rent/in-ireland/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults =
              "/commercial-property-map/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/commercial-property-map/to-rent/in-ireland/"
          }
        }
      } else if (props.tag === "auction") {
        searchFilterResults = "/auction-property/for-sale/in-ireland/"

        if (props.Alias === "upcoming-auctions") {
          searchFilterResults =
            "/services/property-auctions/upcoming-auctions/date-" +
            auction_date +
            "/"
        }
      } else {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/property/to-rent/in-ireland/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/commercial-property/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/commercial-property/to-rent/in-ireland/"
          }
        }
      }
    }


    if (props.searchState?.refinementList?.officeIds) {
      var officePath = true;
      if (props?.free_text_search?.length > 0) {
        if (props?.free_text_search[0] != areaValue) {
          officePath = false
        }
      }
      if (officePath) {
        searchFilterResults += "dng-office-" + props.searchState?.refinementList?.officeIds + "/"
      }

    }

    if (buildingValue) {
      searchFilterResults += "type-" + buildingValue + "/"
    }

    if (bedroomValue && bedroomValue != "0") {
      searchFilterResults += bedroomValue + "-and-more-bedrooms/"
    }

    if (bedroomValue && bedroomValue === "0") {
      searchFilterResults += "studio/"
    }

    if (priceUrl) {
      searchFilterResults += priceUrl + "/"
    }

    if (sizeUrl) {
      searchFilterResults += sizeUrl + "/"
    }

    if (energyRatingValue) {
      searchFilterResults += energyRatingValue + "-rating" + "/"
    }

    // if (mustIncludeValue.length > 0) {
    //   searchFilterResults += "with-" + mustIncludeValue.join("-and-") + "/"
    // }

    if (styleVal && mustIncludeValue.length > 0) {
      searchFilterResults += "with-" + mustIncludeValue.join("-and-") + "-and-" + styleVal + "/";
    } else if (mustIncludeValue.length > 0) {
      searchFilterResults += "with-" + mustIncludeValue.join("-and-") + "/";
    } else if (styleVal) {
      searchFilterResults += "with-" + styleVal + "/"
    }

    if (includes.length > 0 && props.tag != "auction") {
      searchFilterResults += "includes-" + includes.join("-and-").replace(/ /g, "-")+"/";
    }



    props?.setAreaList(areasArr.map(c => c.slug))
    // props.setAreaList(setAreaVal);
    // setAreaVal

    if (statusVal) {
      props.statusChange(statusVal)
    } else {
      props.statusChange(props.statusType)
    }

    console.log("searchFilterResults", searchFilterResults);

    navigate(searchFilterResults)
  }
  // Submit search

  //console.log("mustInclude_Arr", mustInclude_Arr);
  // Keyword Search
  const keywordUpdate = e => {
    var mustIncludeValue = mustInclude_Arr

    // var areaValue = $(".location-search input").val().split(', ').join('-').replace("(", "").replace(")", "").replace(/ /g, "-").replace(/---/g, "-").replace(/'/g, "").toLowerCase();
    // var areaValue = areaVal

    //var areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")
    var areaValue = areaVal
      .replace(/ /g, "-")
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
      .toLowerCase()
    if (areasArr?.length > 0) {
      areaValue = areasArr.map(items => items.slug.toLowerCase()).join("-and-")
      props.freeTextSearch([])
    }

    var bedroomValue = bedVal
      ? bedVal
      : $(".bedroom-input input[type='hidden']").val()

    var energyRatingValue = energy_rating ? energy_rating : "";
    var buildingValue = propertyval
      ? propertyval
      : $(".property input[type='hidden']").val()

    let priceUrl = ""
    if (maxpriceVal || minpriceVal) {
      if (
        minpriceVal &&
        maxpriceVal &&
        minpriceVal != "0" &&
        maxpriceVal != "0"
      ) {
        priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
      } else if (
        (minpriceVal == "" || minpriceVal == "0") &&
        maxpriceVal &&
        maxpriceVal != "0"
      ) {
        priceUrl = "under-" + maxpriceVal
      } else if (
        minpriceVal &&
        (maxpriceVal == "" || maxpriceVal == "0") &&
        minpriceVal != "0"
      ) {
        priceUrl = "above-" + minpriceVal
      }
    }

    // Size
    let sizeUrl = ""
    if (maxsizeVal || minsizeVal) {
      if (minsizeVal && maxsizeVal && minsizeVal != "0" && maxsizeVal != "0") {
        sizeUrl = "size-from-" + minsizeVal + "-to-" + maxsizeVal
      } else if (
        (minsizeVal == "" || minsizeVal == "0") &&
        maxsizeVal &&
        maxsizeVal != "0"
      ) {
        sizeUrl = "size-below-" + maxsizeVal
      } else if (
        minsizeVal &&
        (maxsizeVal == "" || maxsizeVal == "0") &&
        minsizeVal != "0"
      ) {
        sizeUrl = "size-over-" + minsizeVal
      }
    }

    var searchFilterResults = ""

    if (areaValue) {
      if (props.propertyMap === "map") {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property-map/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults = "/property-map/to-rent/in-" + areaValue + "/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults =
              "/commercial-property-map/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults =
              "/commercial-property-map/to-rent/in-" + areaValue + "/"
          }
        }
      } else if (props.tag === "auction") {
        searchFilterResults = "/auction-property/for-sale/in-" + areaValue + "/"
        if (props.Alias === "upcoming-auctions") {
          searchFilterResults =
            "/services/property-auctions/upcoming-auctions/date-" +
            auction_date +
            "/"
        }
      } else {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults = "/property/to-rent/in-" + areaValue + "/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults =
              "/commercial-property/for-sale/in-" + areaValue + "/"
          } else {
            searchFilterResults =
              "/commercial-property/to-rent/in-" + areaValue + "/"
          }
        }
      }
    } else {
      if (props.propertyMap === "map") {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property-map/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/property-map/to-rent/in-ireland/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults =
              "/commercial-property-map/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/commercial-property-map/to-rent/in-ireland/"
          }
        }
      } else if (props.tag === "auction") {
        searchFilterResults = "/auction-property/for-sale/in-ireland/"
        if (props.Alias === "upcoming-auctions") {
          searchFilterResults =
            "/services/property-auctions/upcoming-auctions/date-" +
            auction_date +
            "/"
        }
      } else {
        if (props.department === "residential") {
          if (
            props.department === "residential" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/property/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/property/to-rent/in-ireland/"
          }
        } else if (props.department === "commercial") {
          if (
            props.department === "commercial" &&
            props.propertyType === "sales"
          ) {
            searchFilterResults = "/commercial-property/for-sale/in-ireland/"
          } else {
            searchFilterResults = "/commercial-property/to-rent/in-ireland/"
          }
        }
      }
    }

    if (buildingValue) {
      searchFilterResults += "type-" + buildingValue + "/"
    }

    if (bedroomValue && bedroomValue != "0") {
      searchFilterResults += bedroomValue + "-and-more-bedrooms/"
    }

    if (priceUrl) {
      searchFilterResults += priceUrl + "/"
    }

    if (sizeUrl) {
      searchFilterResults += sizeUrl + "/"
    }

    if (energyRatingValue) {
      searchFilterResults += energyRatingValue + "-rating" + "/"
    }

    // var searchKeywordResults = "";

    if (mustIncludeValue.length > 0) {
      searchFilterResults += "with-" + mustIncludeValue.join("-and-") + "/"
    }

    $(".search-filter-dropdown").removeClass("active-filter")

    props?.setAreaList(areasArr.map(c => c.slug))
    setFocusValue(false)
    navigate(searchFilterResults)
  }
  // Keyword Search

  const submitHandler = event => {
    event.preventDefault()
  }

  // Sales & Lettings radio button
  const onChangeSell = e => {
    if (props.department === "residential") {
      if (props.propertyMap === "map") {
        navigate(`/property-map/for-sale/in-ireland/`)
      } else {
        navigate(`/property/for-sale/in-ireland/`)
      }
    } else if (props.department === "commercial") {
      if (props.propertyMap === "map") {
        navigate(`/commercial-property-map/for-sale/in-ireland/`)
      } else {
        navigate(`/commercial-property/for-sale/in-ireland/`)
      }
    }
  }

  const onChangeRent = e => {
    if (props.department === "residential") {
      if (props.propertyMap === "map") {
        navigate(`/property-map/to-rent/in-ireland/`)
      } else {
        navigate(`/property/to-rent/in-ireland/`)
      }
    } else if (props.department === "commercial") {
      if (props.propertyMap === "map") {
        navigate(`/commercial-property-map/to-rent/in-ireland/`)
      } else {
        navigate(`/commercial-property/to-rent/in-ireland/`)
      }
    }
  }
  // Sales & Lettings radio button

  // search filter dropdown
  const searchFilter = e => {
    e.preventDefault()
    $(".price_dropdown, .size_dropdown").removeClass("active-class")
    $(".search-filter-dropdown").toggleClass("active-filter")
    if(windowWidth<768){
    const getElement=document.getElementById("residentail-search-lists");
    getElement.style.opacity=0
    }
  }

  const showPrice = e => {
    //console.log("showPrice", "showPrice");
    e.preventDefault()
    $(".price_dropdown").toggleClass("active-class")
    $(".size_dropdown").removeClass("active-class")
  }
  const showSize = e => {
    e.preventDefault()
    $(".size_dropdown").toggleClass("active-class")
    $(".price_dropdown").removeClass("active-class")
  }

  // search filter dropdown
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 170)
    })

  }, [])

  var placeHolder_text =
    props?.free_text_search?.length > 0
      ? props?.free_text_search?.toString()
      : ""

      let scrollval = scroll ? "fixedsecond" : ""
  return (
    <>
      <div className={`secondary-header prop-visible ${scrollval} ${props.department} ${props.department === "auction_residential" ? "" : "second"
          }`}
      >
        <div className="container">
          <div className="form-wrap">
            <form action="javascript:;" onSubmit={submitSearch}>
              <input type="hidden" name="sale_agreed_hidden" className="sale_agreed_hidden" id="sale_agreed_hidden"/>
              <div className="form-content">
                {props.tag === "auction" ? (
                  ""
                ) : (
                  <div className="radio-group d-none d-md-flex">
                    <label className="custom-radio">
                      Buy
                      <input
                        type="radio"
                        name="search-type"
                        checked={
                          props.propertyType === "sales" ? "checked" : ""
                        }
                        onChange={onChangeSell}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio">
                      Rent
                      <input
                        type="radio"
                        name="search-type"
                        checked={
                          props.propertyType === "lettings" ? "checked" : ""
                        }
                        onChange={onChangeRent}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                )}
                <div
                  className={`input-wrap location-search predictive-search-box ${props.department === "auction_residential"
                    ? "auction_srch_box"
                    : ""
                    }`}
                >
                  <SearchMultipleArea
                    areaVal={areaVal}
                    placeholder={"Location or Address"}
                    setAreaVal={setAreaVal}
                    setAreasList={setAreasList}
                    handleMultiAreaFun={handleMultiAreaFun}
                    setH1Areas={setH1Areas_Temp}
                    h1_areas={h1_areas_temp}
                    autosuggestInputProps={{
                      className:
                        "react-autosuggest__input refine-autosuggest-input",
                    }}
                  />

                  {/* <SearchResultInputBox areaVal={areaVal} placeHolder={`Location`} department={props?.department} setAreaValue={(area) => {setAreaVal(area.value); setAreaName(area.label); setAreaUrl(area.url)}} /> */}
                  {/* <input type="text" placeholder={"Location"} className="form-control" handleChange={(val)=> setAreaVal(val)} /> */}
                  {/* <select className="d-none d-md-block">
										<option>+ 2 Miles</option>
										<option>+ 4 Miles</option>
										<option>+ 6 Miles</option>
									</select> */}
                </div>
                <ul className="menu-wrap">
                  <li className="price_class price_dropdown">
                    <a
                      href="javascript:;"
                      className="dropdown_select price-dropdown  d-lg-flex"
                      onClick={showPrice}
                    >
                      Min - Max Price
                    </a>

                    <div className="box-wrapper d-lg-flex align-items-center">
                      <div className="select-boxes">
                        <label>Min. Price</label>
                        <Select
                          placeholder={
                            minpriceVal
                              ? props.propertyType === "sales"
                                ? "€" + minpriceVal
                                : "€" + minpriceVal
                              : "No Min. Price"
                          }
                          searchable={false}
                          options={
                            parseInt(selectedMaxPriceValue) === 0
                              ? minPriceList
                              : props.propertyType === "sales"
                                ? minPrice
                                : minPriceLettings
                          }
                          defaultValue={minPriceList.find(
                            obj => obj.value === minpriceVal
                          )}
                          onChange={e => {
                            onChangeMinPrice(e)
                            setMinPriceVal(e[0].value)
                          }}
                          className=""
                        />
                      </div>

                      <div className="select-boxes">
                        <label>Max. Price</label>
                        <Select
                          placeholder={
                            maxpriceVal
                              ? props.propertyType === "sales"
                                ? "€" + maxpriceVal
                                : "€" + maxpriceVal + "pcm"
                              : "No Max. Price"
                          }
                          searchable={false}
                          options={
                            parseInt(selectedMinPriceValue) === 0
                              ? maxPriceList
                              : props.propertyType === "sales"
                                ? maxPrice
                                : maxPriceLettings
                          }
                          defaultValue={maxPriceList.find(
                            obj => obj.value === maxpriceVal
                          )}
                          onChange={e => {
                            onChangeMaxPrice(e)
                            setMaxPriceVal(e[0].value)
                          }}
                          className=""
                        />
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <div className="box-wrapper d-lg-flex align-items-center">
                      <div className="select-boxes">
                        <Select
                          placeholder={
                            maxpriceVal
                              ? props.propertyType === "sales"
                                ? "€" + maxpriceVal
                                : "€" + maxpriceVal + "pcm"
                              : "Max Price"
                          }
                          searchable={false}
                          options={
                            parseInt(selectedMinPriceValue) === 0
                              ? maxPriceList
                              : props.propertyType === "sales"
                              ? maxPrice
                              : maxPriceLettings
                          }
                          defaultValue={maxPriceList.find(
                            obj => obj.value === maxpriceVal
                          )}
                          onChange={e => {
                            onChangeMaxPrice(e)
                            setMaxPriceVal(e[0].value)
                          }}
                          className=""
                        />
                      </div>
                    </div>
                  </li> */}

                  <li
                    className={`price_class size_dropdown ${props.department === "commercial" ? "d-block" : "d-none"
                      }`}
                  >
                    <a
                      href="javascript:;"
                      className="dropdown_select price-dropdown  d-lg-flex"
                      onClick={showSize}
                    >
                      Min - Max Size
                    </a>

                    <div className="box-wrapper d-lg-flex align-items-center">
                      <div className="select-boxes">
                        <label>Min. Size</label>
                        <Select
                          placeholder={minsizeVal ? minsizeVal : "No Min. Size"}
                          searchable={false}
                          options={min_size_options}
                          defaultValue={min_size_options.find(
                            obj => obj.value === minsizeVal
                          )}
                          onChange={e => setMinSizeeVal(parseInt(e[0].value))}
                          className=""
                        />
                      </div>
                      <div className="select-boxes">
                        <label>Max. Size</label>
                        <Select
                          placeholder={maxsizeVal ? maxsizeVal : "No Max. Size"}
                          searchable={false}
                          options={max_size_option}
                          defaultValue={max_size_option.find(
                            obj => obj.value === maxsizeVal
                          )}
                          onChange={e => setMaxSizeVal(parseInt(e[0].value))}
                          className=""
                        />
                      </div>
                    </div>
                  </li>

                  {/* <li className="d-none">
                          <div className="box-wrapper d-lg-flex align-items-center">
                            <div className="select-boxes">
                              <Select
                                placeholder={
                                  maxsizeVal ? maxsizeVal : "Max. Size"
                                }
                                searchable={false}
                                options={max_size_option}
                                defaultValue={max_size_option.find(
                                  obj => obj.value === maxsizeVal
                                )}
                                onChange={e => setMaxSizeVal(parseInt(e[0].value))}
                                className=""
                              />
                            </div>
                          </div>
                        </li> */}

                  <li
                    className={`bedrooms_select ${props.department === "commercial" ? "d-none" : "d-block"
                      }`}
                  >
                    <div className="box-wrapper d-lg-flex align-items-center">
                      <div className={`select-boxes ${bedVal}`}>
                        <Select
                          placeholder={bedVal ? bedVal + " +" : "Bedrooms"}
                          searchable={false}
                          options={bedroomsRange}
                          defaultValue={bedroomsRange.find(
                            obj => obj.value === bedVal
                          )}
                          onChange={e => setBedVal(e[0].value)}
                          className=""
                        />
                      </div>
                    </div>
                  </li>

                  {props.tag === "auction" ? (
                    ""
                  ) : (
                    <li className="property_type_select">
                      <div className="box-wrapper d-lg-flex align-items-center">
                        <div className="select-boxes">
                          {props.department === "residential" && (
                            <Select
                              placeholder={
                                propertyval ? propertyval : "Type"
                              }
                              searchable={false}
                              options={
                                props.propertyType === "sales"
                                  ? resiSalesPropertyType
                                  : resiLettingsPropertyType
                              }
                              defaultValue={
                                props.propertyType === "sales"
                                  ? resiSalesPropertyType.find(
                                    obj => obj.value === propertyval
                                  )
                                  : resiLettingsPropertyType.find(
                                    obj => obj.value === propertyval
                                  )
                              }
                              onChange={e => setPropertyVal(e[0].value)}
                              className=""
                            />
                          )}

                          {props.department === "commercial" && (
                            <Select
                              placeholder={
                                propertyval ? propertyval : "Type"
                              }
                              searchable={false}
                              options={
                                props.propertyType === "sales"
                                  ? commercialSalesPropertyType
                                  : commercialSalesPropertyType
                              }
                              defaultValue={
                                props.propertyType === "sales"
                                  ? commercialSalesPropertyType.find(
                                    obj => obj.value === propertyval
                                  )
                                  : commercialSalesPropertyType.find(
                                    obj => obj.value === propertyval
                                  )
                              }
                              onChange={e => setPropertyVal(e[0].value)}
                              className=""
                            />
                          )}
                        </div>
                      </div>
                      {/* <CustomSelect options={propertyTypeOptions} onSelect={getPropertyTypeHandler}/> */}
                    </li>
                  )}
                  {(props.tag === "auction" ||props.department==="commercial")? (
                    ""
                  ) : (
                    <li className="property_type_select">
                      <div className="box-wrapper d-lg-flex align-items-center">
                        <div className="select-boxes">
                          <Select
                            placeholder={styleVal ? styleVal : "Style"}
                            searchable={false}
                            options={StyleTypes}
                            defaultValue={
                              StyleTypes.find(
                                obj => obj.value === styleVal
                              )
                            }
                            onChange={e => setStyleVal(e[0].value)}
                            className=""
                          />
                        </div>
                      </div>
                      {/* <CustomSelect options={propertyTypeOptions} onSelect={getPropertyTypeHandler}/> */}
                    </li>
                  )}
                </ul>

                {
                  <div
                    className={`filter-wrap ${props.tag === "auction" ? "auction_filter_cls" : ""
                      }`}
                  >
                    <button
                      type="button"
                      className="filter-btn"
                      onClick={searchFilter}
                    >
                      <i className="icon-filter"></i>
                      Filters
                    </button>

                    <div className="backdrop search-filter-dropdown">
                      <Container>
                        <div className="find-property-wrapper">
                          {/* <div className="filter-mobile-container"> */}
                          <div className="find-property keyword_srch">
                            <div
                              className="close-btn hide-mobile"
                              onClick={closeSearchFilter}
                            >
                              <button>
                                <i className="icon-close"></i>
                              </button>
                            </div>

                            <div className="form-wrap one">
                              <div className=" search-filter-mobile-dropdown">
                                {props.tag === "auction" ? (
                                  <div className="close-btn">
                                    <button onClick={() => closeSearchFilter()}>
                                      <i className="icon-close"></i>
                                    </button>
                                  </div>
                                ) : (
                                  <>
                                    <div className="close-btn">
                                      <button
                                        onClick={() => closeSearchFilter()}
                                      >
                                        <i className="icon-close"></i>
                                      </button>
                                    </div>

                                    <div className="radio-group radio-group-search srch_type w-100 d-flex d-md-none">
                                      <label className="custom-radio">
                                        Buy
                                        <input
                                          type="radio"
                                          name="search-type-mobile"
                                          checked={
                                            props.propertyType === "sales"
                                              ? "checked"
                                              : ""
                                          }
                                          onChange={onChangeSell}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                      <label className="custom-radio">
                                        Rent
                                        <input
                                          type="radio"
                                          name="search-type-mobile"
                                          checked={
                                            props.propertyType === "lettings"
                                              ? "checked"
                                              : ""
                                          }
                                          onChange={onChangeRent}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  </>
                                )}

                                <div className="select-boxes">
                                  <Select
                                    placeholder={
                                      minpriceVal
                                        ? props.propertyType === "sales"
                                          ? "€" + minpriceVal
                                          : "€" + minpriceVal
                                        : "Min. Price"
                                    }
                                    searchable={false}
                                    options={
                                      parseInt(selectedMaxPriceValue) === 0
                                        ? minPriceList
                                        : props.propertyType === "sales"
                                          ? minPrice
                                          : minPriceLettings
                                    }
                                    defaultValue={minPriceList.find(
                                      obj => obj.value === minpriceVal
                                    )}
                                    onChange={e => {
                                      onChangeMinPrice(e)
                                      setMinPriceVal(e[0].value)
                                    }}
                                    className=""
                                  />
                                </div>

                                <div className="select-boxes">
                                  <Select
                                    placeholder={
                                      maxpriceVal
                                        ? props.propertyType === "sales"
                                          ? "€" + maxpriceVal
                                          : "€" + maxpriceVal + "pcm"
                                        : "Max. Price"
                                    }
                                    searchable={false}
                                    options={
                                      parseInt(selectedMinPriceValue) === 0
                                        ? maxPriceList
                                        : props.propertyType === "sales"
                                          ? maxPrice
                                          : maxPriceLettings
                                    }
                                    defaultValue={maxPriceList.find(
                                      obj => obj.value === maxpriceVal
                                    )}
                                    onChange={e => {
                                      onChangeMaxPrice(e)
                                      setMaxPriceVal(e[0].value)
                                    }}
                                    className=""
                                  />
                                </div>

                                {props.department === "commercial" ? (
                                  <>
                                    <div className="select-boxes">
                                      <Select
                                        placeholder={
                                          minsizeVal ? minsizeVal : "Min. Size"
                                        }
                                        searchable={false}
                                        options={min_size_options}
                                        defaultValue={min_size_options.find(
                                          obj => obj.value === minsizeVal
                                        )}
                                        onChange={e =>
                                          setMinSizeeVal(parseInt(e[0].value))
                                        }
                                        className=""
                                      />
                                    </div>

                                    <div className="select-boxes">
                                      <Select
                                        placeholder={
                                          maxsizeVal ? maxsizeVal : "Max. Size"
                                        }
                                        searchable={false}
                                        options={max_size_option}
                                        defaultValue={max_size_option.find(
                                          obj => obj.value === maxsizeVal
                                        )}
                                        onChange={e =>
                                          setMaxSizeVal(parseInt(e[0].value))
                                        }
                                        className=""
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className="select-boxes">
                                    <Select
                                      placeholder={
                                        bedVal ? bedVal + " +" : "Bedrooms"
                                      }
                                      searchable={false}
                                      options={bedroomsRange}
                                      defaultValue={bedroomsRange.find(
                                        obj => obj.value === bedVal
                                      )}
                                      onChange={e => setBedVal(e[0].value)}
                                      className=""
                                    />
                                  </div>
                                )}

                                <div className="select-boxes">
                                  <Select
                                    placeholder={
                                      propertyval
                                        ? propertyval
                                        : "Property Type"
                                    }
                                    searchable={false}
                                    options={
                                      props.propertyType === "sales"
                                        ? resiSalesPropertyType
                                        : resiLettingsPropertyType
                                    }
                                    defaultValue={
                                      props.department === "residential"
                                        ? resiSalesPropertyType.find(
                                          obj => obj.value === propertyval
                                        )
                                        : resiLettingsPropertyType.find(
                                          obj => obj.value === propertyval
                                        )
                                    }
                                    onChange={e => setPropertyVal(e[0].value)}
                                    className=""
                                  />
                                </div>
                                <div className="select-boxes">
                                  <Select
                                    placeholder={styleVal ? styleVal : "Style"}
                                    searchable={false}
                                    options={StyleTypes}
                                    defaultValue={
                                      StyleTypes.find(
                                        obj => obj.value === styleVal
                                      )
                                    }
                                    onChange={e => setStyleVal(e[0].value)}
                                    className=""
                                  />
                                </div>
                              </div>

                              {props.tag !== "auction" && (
                                <>
                                  <SearchKeyword
                                    department={props.department}
                                    keywordUpdate={keywordUpdate}
                                    setMustIncludeArr={setMustIncludeArr}
                                    mustInclude_Arr={mustInclude_Arr}
                                    setFocusValue={setFocusValue}
                                    focus_cls={focus_cls}
                                  />

                                  <div className="col-12 mt-4">
                                    <div className="select-boxes filter_egrating find-property-dropdown">
                                      <label className="mb-3">
                                        Min. Energy Rating
                                      </label>
                                      <EnergyRating
                                        setEnergyRating={setEnergyRating}
                                        energy_rating={energy_rating}
                                        energyRating={energyRating}
                                      />


                                      {/* <Select
                                        placeholder={
                                          energy_rating
                                            ? energy_rating
                                            : "Min. Energy Rating"
                                        }
                                        searchable={false}
                                        options={energyRating}
                                        defaultValue={energyRating.find(
                                          obj => obj.value === energy_rating
                                        )}
                                        onChange={e =>
                                          setEnergyRating(e[0].value)
                                        }
                                        className=""
                                      /> */}

                                    </div>
                                  </div>
                                </>
                              )}

                              {props.tag !== "auction" && (
                                <div className="col-12 mt-4 mb-4">
                                  <label className="mb-3">Include Status</label>
                                  <div className="form-content status_srch">
                                    {props.tag === "auction" ? (
                                      ""
                                    ) : (
                                      <div className="checkbox-group checkbox-group-search d-flex w-100">
                                        {props.propertyType === "sales" ? (
                                          <>
                                            <label className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                name="search-status"
                                                value={"sale agreed"}
                                                onChange={handlCheck}
                                                checked={
                                                  (statusVal.includes("sale agreed") || statusVal.includes("Sale Agreed"))
                                                    ? true
                                                    : false
                                                }
                                              />{" "}
                                              Sale Agreed
                                              <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                name="search-status"
                                                onChange={handlCheck}
                                                value={"sold"}
                                                // checked={
                                                //   statusVal.includes("sold")
                                                //     ? true
                                                //     : false
                                                // }
                                              />{" "}
                                              Sold
                                              <span className="checkmark"></span>
                                            </label>
                                          </>
                                        ) : props.propertyType ===
                                          "lettings" ? (
                                          <>
                                            <label className="custom-checkbox">
                                              <input
                                                type="checkbox"
                                                name="search-status"
                                                value={"let agreed"}
                                                onChange={handlCheck}
                                                checked={
                                                  statusVal.includes("let agreed")|| statusVal.includes("Let Agreed")
                                                    ? true
                                                    : false
                                                }
                                              />{" "}
                                              Let Agreed
                                              <span className="checkmark"></span>
                                            </label>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>

                            <button
                              type="submit"
                              className="btn update-btn mbl_btn w-100"
                            //onClick={submitSearch}
                            >
                              Update
                            </button>
                          </div>
                          {/* </div> */}
                        </div>
                      </Container>
                    </div>
                  </div>
                }

                <button
                  type="submit"
                  className="btn update-btn"
                //onClick={submitSearch}
                >
                  Update
                </button>
                {
                  isMobile&&
                  <button  type="submit" className="search-btn-listing">Search</button>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="predictive-arelist-suggestion property-results p-0">
        <AreaListBox areas={areasArr} removeArea={removeArea} />
      </div>
    </>
  )
}

export default SecondaryHeader
